
import React from 'react';
import AppRoutes from './Routes/AppRoutes';
import './App.css'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './Store/store';
import { Provider } from 'react-redux';


function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <AppRoutes />
    </Provider>
  );
}

export default App;
