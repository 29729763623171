import React, { useEffect, useState } from 'react';
import './statistics.css';
import LineChart from '../../LineChart/LineChart';
import { BASE_URL } from '../../../constants';
import Select from 'react-select';
import { selectStyles } from '../../Modals/AppointmentModal/AppointmentModal';

const EarnedMoney = () => {
    const [dataRange, setDataRange] = useState('Monthly');
    const [selectedCoach, setSelectedCoach] = useState();
    const [coachesOptions, setCoachesOptions] = useState([]);
    const userToken = localStorage.getItem('frotaToken');

    const handleDataRangeChange = (range) => {
        setDataRange(range);
    };

    const [monthlyData, setMonthlyData] = useState({});
    const [yearlyData, setYearlyData] = useState({});

    useEffect(() => {
        const fetchCoaches = async () => {
            try {
                const response = await fetch(`${BASE_URL}admin/users?type=coach`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch coaches');
                }

                const json = await response.json();
                const coachesData = json.data.map(coach => ({
                    label: coach.name,
                    value: coach.id
                }));
                setCoachesOptions(coachesData);
            } catch (error) {
                console.error('Error fetching coaches:', error);
                // Handle error gracefully
            }
        };

        fetchCoaches();
    }, [userToken]); // Include userToken in the dependency array

    const fetchCreditsForCoach = async () => {
        try {
            if (!selectedCoach) return; // Make sure a coach is selected
            const response = await fetch(`${BASE_URL}admin/coach_credit_stats`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to get Credits");
            }

            const json = await response.json();

            // console.log(json.data)

            // Filter data for the selected coach
            const coachCredits = json?.data?.filter(item => item.coachId === selectedCoach.value);

            if (coachCredits.length > 0) {
                // Process data to get monthly and yearly credits
                const monthlyCredits = {};
                const yearlyCredits = {};

                coachCredits.forEach(credit => {
                    const creditedAt = new Date(credit.created);
                    const month = creditedAt.getMonth(); // Get month index

                    // Update monthly credits
                    if (!monthlyCredits[month]) {
                        monthlyCredits[month] = 0;
                    }
                    monthlyCredits[month] += credit.credits;

                    // Update yearly credits
                    const year = creditedAt.getFullYear();
                    if (!yearlyCredits[year]) {
                        yearlyCredits[year] = 0;
                    }
                    yearlyCredits[year] += credit.credits;
                });

                // Array of month names
                const monthNames = [
                    'January', 'February', 'March', 'April', 'May', 'June',
                    'July', 'August', 'September', 'October', 'November', 'December'
                ];

                // Create datasets for Chart.js
                const monthlyLabels = Object.keys(monthlyCredits).map(month => monthNames[parseInt(month)]);
                const monthlyValues = Object.values(monthlyCredits);
                const monthlyDataset = {
                    labels: monthlyLabels,
                    datasets: [
                        {
                            label: 'Credits',
                            backgroundColor: '#e1070766',
                            borderColor: '#e10707',
                            fill: {
                                target: 'origin',
                                above: '#e1070766',   // Area will be red above the origin
                                below: '#e1070711'    // And blue below the origin
                            },
                            borderWidth: 2,
                            data: monthlyValues
                        }
                    ]
                };

                const yearlyLabels = Object.keys(yearlyCredits).map(year => `Year ${year}`);
                const yearlyValues = Object.values(yearlyCredits);
                const yearlyDataset = {
                    labels: yearlyLabels,
                    datasets: [
                        {
                            label: 'Yearly Credits',
                            backgroundColor: '#e1070766',
                            borderColor: '#e10707',
                            borderWidth: 2,
                            fill: {
                                target: 'origin',
                                above: '#e1070766',   // Area will be red above the origin
                                below: '#e1070711'    // And blue below the origin
                            },
                            data: yearlyValues
                        }
                    ]
                };

                setMonthlyData(monthlyDataset);
                setYearlyData(yearlyDataset);
            } else {
                // If no data is available for the selected coach, reset the state
                setMonthlyData({});
                setYearlyData({});
            }
            // Process json data as needed
        } catch (error) {
            console.error('Error fetching credits:', error);
            // Handle error gracefully
        }
    };

    useEffect(() => {
        fetchCreditsForCoach();
    }, [selectedCoach, userToken]); // Include selectedCoach and userToken in the dependency array

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    };

    return (
        <div className='stats-container'>
            <div className="title-container">
                <h2 className="title">CREDITS PER COACH</h2>
            </div>
            <div className='btn-container'>
                <button className="monthly-btn" onClick={() => handleDataRangeChange('Monthly')}>Monthly</button>
                <button className='yearly-btn' onClick={() => handleDataRangeChange('Yearly')}>Yearly</button>
            </div>

            <div className="select-coach-container">
                <label htmlFor="">Select a Coach:</label>
                <Select
                    value={selectedCoach}
                    onChange={(option) => setSelectedCoach(option)}
                    options={coachesOptions}
                    placeholder="Select a Coach"
                    styles={selectStyles}
                />
            </div>
            <LineChart data={dataRange === 'Monthly' ? monthlyData : yearlyData} options={options} />
        </div>
    );
};

export default EarnedMoney;
