import React from 'react'
import './adminDashboard.css'
import Admin from '../../Components/Admin/Admin'

const AdminDashboard = () => {
    return (
        <div>
            <Admin />
        </div>
    )
}

export default AdminDashboard