import React from 'react';
import './admin.css';
import SharedCalendar from '../SharedCalendar/SharedCalendar';
import { Link } from 'react-router-dom';

const Admin = () => {
  return (
    <div className='admin-dashboard-container'>
      <div className="box-container">
        <div className='col-3'>
          <div className='title-container'>
            <h2 className='title'>STUDENT</h2>
          </div>
          <div className='btn-container'>
            <Link to='/add-student' className='btn-link'>
              <button className='btn'>Add Student</button>
            </Link>
            <Link to='/student-list' className='btn-link'>
              <button className='btn'>List of Students</button>
            </Link>
          </div>
        </div>
        <div className='col-3'>
          <div className='title-container'>
            <h2 className='title'>COACHES</h2>
          </div>
          <div className='btn-container'>
            <Link to='/add-coach' className='btn-link'>
              <button className='btn'>Add Coach</button>
            </Link>
            <Link to='/coach-list' className='btn-link'>
              <button className='btn'>List of Coaches</button>
            </Link>
            <Link to='/add-speciality' className='btn-link'>
              <button className='btn'>Add Service</button>
            </Link>
            <Link to='/speciality-list' className='btn-link'>
              <button className='btn'>List of Services</button>
            </Link>
          </div>
        </div>
        <div className='col-3'>
          <div className='title-container'>
            <h2 className='title'>STATISTICS</h2>
          </div>
          <div className='btn-container'>
            <Link to='/classes-per-coach' className='btn-link'>
              <button className='btn'>Monthly/Yearly Classes per Coach</button>
            </Link>
            <Link to='/classes-per-student' className='btn-link'>
              <button className='btn'>Monthly/Yearly Classes per Student</button>
            </Link>
            {/* <Link to='/earned-money' className='btn-link'>
              <button className='btn'>Monthly/Yearly Earned Money</button>
            </Link> */}
          </div>
        </div>
      </div>
      <SharedCalendar />
    </div>
  );
};

export default Admin;
