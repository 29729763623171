import { createSlice } from "@reduxjs/toolkit";

// Define initial state for the user profile
const initialState = {
    coach: {}, // Initial user profile data
    refreshCoach: false, // Initial value for refreshCoach variable
    // Add other user profile state variables here if needed
};

// Define the user profile slice
export const coach = createSlice({
    name: "coach",
    initialState,
    reducers: {
        // Action to update user profile
        updateCoach: (state, action) => {
            state.coach = action.payload; // Set user profile to the payload value
        },
        // Action to update refreshCoach variable
        updateRefreshCoach: (state, action) => {
            state.refreshCoach = action.payload; // Set refreshCoach variable to the payload value
        },
        // Add other actions to update user profile state here if needed
    },
});

// Export actions
export const { updateCoach, updateRefreshCoach } = coach.actions;

// Selector to access user profile state
export const selectCoach = (state) => state.coach.coach;

// Selector to access refreshCoach state
export const selectRefreshCoach = (state) => state.coach.refreshCoach;

// Reducer for user profile slice
export default coach.reducer;
