import React, { useEffect, useState } from 'react';
import './studentCoachList.css';
import { BASE_URL } from '../../../constants';
import Loader from '../../Loader/Loader';
import { useNavigate } from 'react-router';

const records = [
    { id: 1, name: 'John Doe', email: 'john@example.com', phone: '123-456-7890', credits: 10, address: '123 Main St', description: 'Lorem ipsum dolor sit amet' },
    { id: 2, name: 'Jane Smith', email: 'jane@example.com', phone: '987-654-3210', credits: 15, address: '456 Elm St', description: 'Consectetur adipiscing elit' }
];

const StudentCoachList = ({ title }) => {

    const navigate = useNavigate();

    const userToken = localStorage.getItem('frotaToken')

    const [recordsList, setRecordsList] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const handleRecordClick = async (id) => {
        if (title === 'Student') {
            navigate(`/update-student/${id}`);
        } else if (title === 'Coach') {
            navigate(`/update-coach/${id}`)
        }
    }

    useEffect(() => {
        const type = title === 'Student' ? 'student' : 'coach'
        const getRecordsList = async () => {
            setIsLoading(true)
            try {
                const response = await fetch(`${BASE_URL}admin/users?type=${type}`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const json = await response.json();
                setRecordsList(json.data);
                // console.log('Data:', json);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
            setIsLoading(false);
        };

        getRecordsList();

    }, [])

    return (
        <div className="student-list-container">
            <h2 className='title'>{title === 'Student' ? 'STUDENT LIST' : 'COACH LIST'}</h2>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Credits</th>
                            {title !== 'Student' && <th>Service</th>}
                            {title !== 'Student' && <th>Charges</th>}
                            <th>Address</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <Loader isLoading={isLoading} />
                    {recordsList?.length > 0 ? (
                        <tbody>
                            {recordsList.map(record => (
                                <tr key={record.id} onClick={() => handleRecordClick(record.id)}>
                                    <td>{record.id}</td>
                                    <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{record.name}</td>
                                    <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{record.email}</td>
                                    <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{record.phone}</td>
                                    <td>{record.credits}</td>
                                    {title !== 'Student' && (
                                        <td>
                                            <div className='services-container'>
                                                {record.specialities.map((service, index) => (
                                                    <p key={index} className='service-list'>{service.name}</p>
                                                ))}
                                            </div>
                                        </td>
                                    )}
                                    {title !== 'Student' && <td>{record.charges}</td>}
                                    <td>{record.address}</td>
                                    <td>{record.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="7" className='no-data-message'>
                                    <p>No {title === 'Student' ? 'Students' : 'Coaches'} Available</p>
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>

    );
};

export default StudentCoachList;
