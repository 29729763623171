import React, { useEffect, useState } from 'react';
import './../Admin/Statistics/statistics.css';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../Store/App/userProfile';
import { BASE_URL } from '../../constants';
import LineChart from '../LineChart/LineChart';
import Select from 'react-select';
import { selectStyles } from '../Admin/AddCoach';
import AppointmentsStatsList from '../Admin/Statistics/AppointmentsStatsList';

const CoachStatistics = () => {

    const userProfile = useSelector(selectUserProfile);
    const userToken = localStorage.getItem('frotaToken');

    console.log("User Profile: ", userProfile)

    const [selectedMonth, setSelectedMonth] = useState({ label: 'All Months', value: 0 });
    const [selectedYear, setSelectedYear] = useState();
    const [appointmentsList, setAppointmentsList] = useState([]);

    const months = [
        { label: 'All Months', value: 0 },
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
    ];

    const years = [
        { label: '2024', value: 2024 },
        { label: '2025', value: 2025 },
        { label: '2026', value: 2026 },
        { label: '2027', value: 2027 },
        // { label: '2028', value: 2028 },
        // { label: '2029', value: 2029 },
        // { label: '2030', value: 2030 },
    ];


    const fetchStatsForCoach = async () => {
        try {
            const response = await fetch(`${BASE_URL}users/monthly_classes/?${selectedMonth.value === 0 ? '' : `month=${selectedMonth.value}`}${selectedYear.value === 0 ? '' : `&year=${selectedYear.value}`}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to get Stats");
            }

            const json = await response.json();

            // console.log(json.data)

            if (json.data) {

                // console.log("Data: ", json.data)

                let appointments = [];

                json.data.map((data, index) => {
                    if (data?.appointments?.length > 0) {
                        data.appointments.map(appointment => {
                            if (appointment?.status === 'ended') {
                                appointments.push(appointment);
                            }
                        })
                    }
                })

                // console.log("Appointments: ", appointments);
                setAppointmentsList(appointments);

            }
            // Process json data as needed
        } catch (error) {
            console.error('Error fetching stats:', error);
            // Handle error gracefully
        }
    };

    useEffect(() => {
        const currentTime = new Date();
        const month = currentTime.getMonth() + 1;
        setSelectedMonth(months[month]);

        const year = currentTime.getFullYear();
        setSelectedYear({ label: year.toString(), value: year })
    }, [])

    useEffect(() => {
        fetchStatsForCoach();

    }, [selectedMonth, selectedYear, userToken]); // Include selectedCoach and userToken in the dependency array
    // Include selectedCoach and userToken in the dependency array

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    };

    return (
        <div className='stats-container'>
            <div className="title-container">
                <h2 className="title">{userProfile.name}'s Stats</h2>
            </div>

            <div className='select-time-container'>

                <div className="select-coach-container">
                    <label htmlFor="">Select Month:</label>
                    <Select
                        value={selectedMonth}
                        onChange={(option) => setSelectedMonth(option)}
                        options={months}
                        placeholder="Select Month"
                        styles={selectStyles}
                    />
                </div>

                <div className="select-coach-container">
                    <label htmlFor="">Select Year:</label>
                    <Select
                        value={selectedYear}
                        onChange={(option) => setSelectedYear(option)}
                        options={years}
                        placeholder="Select Year"
                        styles={selectStyles}
                    />
                </div>
            </div>

            <AppointmentsStatsList
                title={userProfile.roles[0] === 'coach' ? 'Coach' : 'Student'}
                viewAs={userProfile.roles[0]}
                appointments={appointmentsList}
                month={selectedMonth?.label}
                year={selectedYear?.label} />
        </div>
    );
};

export default CoachStatistics;
