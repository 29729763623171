import React, { useEffect, useState } from "react";
import './RequestToApprove.css'
import { BASE_URL } from "../../../constants";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import AddCommentModal from "../../Modals/AddCommentModal";

const status = {
    APPROVE: 'approved',
    DECLINE: 'declined'
}

const RequestToApprove = ({ request, refresh, setRefresh }) => {

    const [isLoading, setIsLoading] = useState(false);

    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
    const [confirmStatus, setConfirmStatus] = useState('');

    const userToken = localStorage.getItem('frotaToken');

    const titleCase = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    // console.log(request)

    const handleStatus = async (status, comments) => {
        setIsLoading(true)

        const requestBody = {
            status: status,
        }

        if (comments?.length > 0) {
            requestBody.comments = comments
        }

        try {
            const response = await fetch(`${BASE_URL}coach/appointments/respond/${request.id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                setIsLoading(false);
                toast.error('Error Responding to Request.')
                throw new Error('Failed to approve the request.');
            }

            const json = await response.json()
            // console.log('Status Result: ', json)
            setRefresh(!refresh);

            if (json.success) {
                // Handle success
                toast.success(`Request ${titleCase(status)} successfully.`);
                console.log(`Request ${titleCase(status)} successfully.`);
            }
        } catch (error) {
            // Handle error
            console.error('Error occurred while approving the request:', error.message);
        }
        setIsLoading(false);
    };

    const handleRequest = (status) => {
        setConfirmStatus(status);
        setIsCommentModalOpen(true);
    }

    return (
        <div className="request-to-approve">
            <AddCommentModal status={confirmStatus}
                isOpen={isCommentModalOpen}
                onClose={() => setIsCommentModalOpen(false)}
                onSubmit={handleStatus} />
            <Loader isLoading={isLoading} />
            <div className="request-details">
                <div className="fields">
                    <label className="label">Student:</label>
                    <p className="student-name">{request.studentName}</p>
                </div>

                <div className="fields">
                    <label className="label">Class:</label>
                    <p className="student-name">{request.specialityName}</p>
                </div>

                <div className="fields">
                    <label className="label">Date:</label>
                    <p className="date">{request.date}</p>
                </div>

                <div className="fields">
                    <label className="label">Time:</label>
                    <p className="time">{request.time}</p>
                </div>
            </div>
            <div className="request-btns-container">
                <button
                    className={`approve-button`}
                    onClick={() => handleRequest(status.APPROVE)}
                >
                    Accept
                </button>
                <button
                    className={`decline-button`}
                    onClick={() => handleRequest(status.DECLINE)}
                >
                    Decline
                </button>
            </div>
        </div>
    );
};

export default RequestToApprove;
