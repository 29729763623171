import React, { useEffect, useState } from 'react';
import './admin.css'
import ImageUploader from '../ImageUploader/ImageUploader';
import { BASE_URL, passwordPattern } from '../../constants';
import Loader from '../Loader/Loader';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faPen } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { selectStyles } from './AddCoach';


const UpdateCoach = () => {

    const userToken = localStorage.getItem('frotaToken')

    const navigate = useNavigate();

    const { id } = useParams();
    // State variables to store form inputs
    const [isLoading, setIsLoading] = useState(false);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [services, setServices] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState(null);

    const [coachData, setCoachData] = useState(null)
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [credits, setCredits] = useState('');
    const [price, setPrice] = useState('');
    const [clientDescription, setClientDescription] = useState('');
    const [profileImage, setProfileImage] = useState(null);

    const [isCreditsEdit, setIsCreditsEdit] = useState(false);

    const [viewPassword, setViewPassword] = useState(false);

    useEffect(() => {
        const getCoachData = async () => {
            setIsLoading(true);

            try {
                const response = await fetch(`${BASE_URL}admin/users?type=coach`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const json = await response.json();

                const coachRecord = json?.data?.find(record => record.id === parseInt(id, 10));

                if (coachRecord) {
                    setCoachData(coachRecord);
                    setName(coachRecord.name);
                    setAddress(coachRecord.address);
                    setPhone(coachRecord.phone);
                    setEmail(coachRecord.email);
                    setCredits(coachRecord.credits);
                    setClientDescription(coachRecord.description);
                    setProfileImage(coachRecord.avatar);
                    // setSelectedSpeciality(coachRecord.specialityId);
                    // setSelectedSpeciality({ label: coachRecord?.speciality?.name, value: coachRecord?.specialityId });
                    setPrice(coachRecord.charges);

                    if (coachRecord.specialities?.length > 0) {
                        const newSelectedSpeciality = coachRecord.specialities.map(service => ({
                            label: service.name,
                            value: service.id
                        }));

                        setSelectedSpeciality(prevSelectedSpeciality => {
                            if (Array.isArray(prevSelectedSpeciality)) {
                                return [...prevSelectedSpeciality, ...newSelectedSpeciality];
                            } else {
                                return [...newSelectedSpeciality];
                            }
                        });
                    }

                } else {
                    // Handle the case where no coach record is found with the given ID
                    console.error('Coach record not found for ID:', id);
                }
            } catch (error) {
                // Handle fetch or parsing errors
                console.error("Error:", error);
            }


        };
        getCoachData();
    }, [id, userToken]);

    const handleUpdateCredits = async () => {
        if (credits === coachData.credits) {
            toast.success('Credits Updated.')
            setIsCreditsEdit(false);
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}admin/credits/update`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'application/json' // Make sure to include this header for JSON requests
                },
                body: JSON.stringify({
                    id: id,
                    credits: credits
                })
            });

            if (response.ok) {
                const data = await response.json();
                toast.success('Credits Updated.');
                setIsCreditsEdit(false);
                // console.log('Credits Updated Successfully:', data);
            } else {
                const error = await response.json();
                console.error('Failed to update credits:', error);
                toast.error('Error Updating Credits. Please Try Again.')
            }
        } catch (error) {
            console.error('An error occurred while updating credits:', error);
        }

        setIsLoading(false);
    }

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password) {
            const isValidPassword = passwordPattern.test(password);
            if (!isValidPassword) {
                toast.error('Password must contain at least One Uppercase letter, one Lowercase letter, one Number, and one Special Character, and be at least 8 characters long.')
                return;
            }
        }

        setIsLoading(true);

        const formData = new FormData();

        if (name && name !== coachData.name) {
            formData.append('name', name);
        }
        if (phone && phone !== coachData.phone) {
            formData.append('phone', phone);
        }
        if (email && email !== coachData.phone) {
            formData.append('email', email);
        }
        if (password) {
            formData.append('password', password);
        }
        if (address && address !== coachData.address) {
            formData.append('address', address);
        }
        if (clientDescription && clientDescription !== coachData.description) {
            formData.append('description', clientDescription);
        }
        if (profileImage && profileImage !== coachData.avatar) {
            formData.append('image', profileImage);
        }
        // if (selectedSpeciality && selectedSpeciality?.value != coachData?.specialityId) {
        //     formData.append('specialityId', selectedSpeciality.value);
        // }
        if (selectedSpeciality && selectedSpeciality?.length > 0) {
            const specialityIds = selectedSpeciality.map(speciality => speciality.value);

            specialityIds.forEach(id => {
                formData.append('specialityId[]', id);
                // formData.append('specialityId', id);
            });
        } else if (selectedSpeciality?.length === 0) {
            toast.error("Please Select atleast One Service.")
            setIsLoading(false);
            return;
        }
        if (price && price !== coachData.charges) {
            formData.append('charges', price);
        }

        try {
            const response = await fetch(`${BASE_URL}users/update/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${userToken}`
                },
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    toast.success('Coach Updated Successfully.');
                    setIsLoading(false);

                    // alert(data.message);
                }
                // console.log('Coach Update successful:', data);
                // Handle successful sign-up, such as displaying a success message
                return data; // Return data if further processing is needed
            } else {
                // Handle sign-up failure
                const error = await response.json();
                console.error('Coach Update failed:', error);
                setIsLoading(false);

                // Handle error, such as displaying an error message
                throw new Error('Coach Update failed'); // Throw error for further handling
            }
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle network errors or other exceptions
            setIsLoading(false);
            throw new Error('An unexpected error occurred'); // Throw error for further handling
        }


        // Clear form fields after submission
        clearForm();
    };

    const handleDeleteCoach = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}admin/remove_user/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${userToken}`
                }
            });

            if (response.ok) {
                toast.success('User deleted successfully.');
                navigate('/coach-list')
            } else {
                setIsLoading(false);
                toast.error('Error Deleting Coach. Please Try Again.')
                throw new Error('Failed to delete user.');
            }
        } catch (error) {
            console.error('An error occurred while deleting user:', error);
            toast.error('An unexpected error occurred.');
        }
        setIsLoading(false);
    }


    const handleServiceChange = (selectedOption) => {
        setSelectedSpeciality(selectedOption);
    };

    // Function to clear form fields
    const clearForm = () => {
        setName('');
        setAddress('');
        setPhone('');
        setEmail('');
        setClientDescription('');
    };

    const fetchSpecialities = async (specialityId) => {
        try {
            const response = await fetch(`${BASE_URL}speciality`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if (response.ok) {
                const json = await response.json();
                setServices(json.data);

                const service = json.data.find(speciality => speciality.id === specialityId);
                if (service) {
                    setSelectedSpeciality({ label: service.name, value: service.id });
                }

                // console.log(json.data)
            } else {
                console.error('Failed to fetch specialities:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching specialities:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchSpecialities(selectedSpeciality);

    }, [])

    return (
        <div className='add-student-container'>
            <Loader isLoading={isLoading} />
            <form onSubmit={handleSubmit}>
                <h2 className='title'>UPDATE COACH</h2>
                <div className="form-container">
                    <div className="left-container">
                        <div className='form-field'>
                            <label htmlFor='name'>Name: </label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                placeholder="Enter Coach's Name"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='email'>Email: </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                placeholder="Enter Coach's Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='phone'>Phone: </label>
                            <input
                                type="text"
                                id="phone"
                                value={phone}
                                placeholder="Enter Phone Number"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='password'>Password: </label>
                            <input
                                type={viewPassword ? 'text' : 'password'}
                                id="password"
                                value={password}
                                placeholder="Enter Coach's Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className='view-password-icon-container'>
                                <FontAwesomeIcon
                                    icon={viewPassword ? faEye : faEyeSlash}
                                    onClick={() => setViewPassword(!viewPassword)}
                                    className='view-password-icon' />
                            </div>
                        </div>
                        <div className='update-credits'>
                            <div className='form-field' style={{ position: 'relative' }}>
                                <label htmlFor='credits'>Credits: </label>
                                <input
                                    type="number"
                                    id="credits"
                                    value={credits}
                                    placeholder="Enter Credits"
                                    onChange={(e) => setCredits(e.target.value)}
                                    disabled={!isCreditsEdit}
                                />
                            </div>
                            {isCreditsEdit ?
                                <>
                                    <button type='button' className='save-credits' onClick={handleUpdateCredits}>
                                        Update Credits
                                    </button>
                                    <button type='button' className='cancel-credits' onClick={() => setIsCreditsEdit(false)}>
                                        Cancel
                                    </button>
                                </>
                                :
                                <button type='button' className='edit-credits' onClick={() => setIsCreditsEdit(true)}>
                                    <FontAwesomeIcon icon={faPen} size='xs' className='me-1' />Change Credits
                                </button>}

                        </div>
                        <div className='select-field'>
                            <label className='select-label'>Select Service:</label>
                            <Select
                                value={selectedSpeciality}
                                onChange={handleServiceChange}
                                options={services.map(option => ({
                                    value: option.id,
                                    label: option.name,
                                    imageUrl: option.mediaUrl
                                }))}
                                styles={selectStyles}
                                isMulti={true}
                            />
                        </div>

                        <div className='form-field'>
                            <label htmlFor='phone'>Price: </label>
                            <input
                                type="text"
                                id="price"
                                value={price}
                                placeholder="Enter Price"
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>


                    </div>
                    <div className="right-container">
                        <ImageUploader profileImage={profileImage} setProfileImage={setProfileImage} />
                    </div>
                </div>

                <div className="bottom-container">
                    <div className='form-field'>
                        <label htmlFor='address'>Address: </label>
                        <input
                            type="text"
                            id="address"
                            value={address}
                            placeholder="Enter Coach's Address"
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <div className='form-field'>
                        <label htmlFor='clientDescription'>Client Description: </label>
                        <textarea
                            type="text"
                            id="clientDescription"
                            value={clientDescription}
                            rows={4}
                            placeholder='Enter Some Client Description...'
                            onChange={(e) => setClientDescription(e.target.value)}
                        />
                    </div>

                    <div className="btn-container">
                        <button type="submit" className='submit-btn'>Update Coach</button>
                        <button type='button' className='delete-btn' onClick={() => setIsDeleteModalOpen(true)}>Delete Coach</button>
                    </div>

                    <ConfirmationModal
                        isOpen={isDeleteModalOpen}
                        onRequestClose={() => setIsDeleteModalOpen(false)}
                        onConfirm={handleDeleteCoach}
                        message="Are you sure you want to delete this Coach?"
                    />
                </div>

            </form>
        </div>
    )
}

export default UpdateCoach;
