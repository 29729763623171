import React, { useEffect, useState } from 'react';
import './specialities.css'
import { BASE_URL } from '../../../constants';
import Loader from '../../Loader/Loader';
import { toast } from 'react-toastify';
import BoxImageUploader from '../../ImageUploader/BoxImageUploader';
import { useNavigate, useParams } from 'react-router';

const UpdateSpeciality = () => {

    const userToken = localStorage.getItem('frotaToken');

    const { id } = useParams();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [specialityName, setSpecialityName] = useState('');
    const [specialityImage, setSpecialityImage] = useState(null);
    const [oldSpecialityImage, setOldSpecialityImage] = useState(null);

    useEffect(() => {
        const fetchSpecialities = async () => {
            try {
                const response = await fetch(`${BASE_URL}speciality`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                });
                if (response.ok) {
                    const json = await response.json();
                    const speciality = json.data.find(record => record.id === parseInt(id, 10))
                    if (speciality) {
                        setSpecialityName(speciality?.name);
                        setSpecialityImage(speciality?.mediaUrl);
                        setOldSpecialityImage(speciality?.mediaUrl);
                    }
                } else {
                    console.error('Failed to fetch specialities:', response.statusText);
                }
            } catch (error) {
                console.error('An error occurred while fetching specialities:', error);
            }
            setIsLoading(false);
        };

        fetchSpecialities();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!specialityName) {
            toast.error('Please Enter a Speciality Name.')
        }
        setIsLoading(true);

        const formData = new FormData();
        formData.append('name', specialityName);
        if (specialityImage && specialityImage !== oldSpecialityImage) {
            formData.append('image', specialityImage);
        }

        try {
            const response = await fetch(`${BASE_URL}speciality/update/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${userToken}`
                },
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    toast.success('Speciality Updated Successfully.');
                    setIsLoading(false);
                    setSpecialityName('');
                    setSpecialityImage(null);
                    setOldSpecialityImage(null);
                    navigate(-1);
                }
            } else {
                const error = await response.json();
                console.error('Failed to Update speciality:', error);
                toast.error('Failed to Update speciality. Please try again.');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An unexpected error occurred. Please try again later.');
            setIsLoading(false);
        }
    };

    return (
        <div className="add-student-container">
            <Loader isLoading={isLoading} />
            <form onSubmit={handleSubmit}>
                <h2 className="title">UPDATE SERVICE</h2>
                <div className="form-group p-4">
                    <label htmlFor="specialityName">Service Name:</label>
                    <input
                        type="text"
                        id="specialityName"
                        value={specialityName}
                        onChange={(e) => setSpecialityName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <BoxImageUploader profileImage={specialityImage} setProfileImage={setSpecialityImage} />
                </div>
                <div className="btn-container">
                    <button type="submit" className='submit-btn'>Update Service</button>
                </div>
            </form>
        </div>
    );
};

export default UpdateSpeciality;
