import React, { useState } from 'react';
import './specialities.css'
import { BASE_URL } from '../../../constants';
import Loader from '../../Loader/Loader';
import { toast } from 'react-toastify';
import BoxImageUploader from '../../ImageUploader/BoxImageUploader';
import { useNavigate } from 'react-router';

const AddSpeciality = () => {

    const userToken = localStorage.getItem('frotaToken');

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [specialityName, setSpecialityName] = useState('');
    const [specialityImage, setSpecialityImage] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!specialityName) {
            toast.error('Please Enter a Speciality Name.')
        }
        setIsLoading(true);

        const formData = new FormData();
        formData.append('name', specialityName);
        if (specialityImage) {
            formData.append('image', specialityImage);
        }

        try {
            const response = await fetch(`${BASE_URL}speciality/create`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`
                },
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    toast.success('Speciality added successfully.');
                    setIsLoading(false);
                    setSpecialityName('');
                    setSpecialityImage(null);
                    navigate(-1);
                }
            } else {
                const error = await response.json();
                console.error('Failed to add speciality:', error);
                toast.error('Failed to add speciality. Please try again.');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An unexpected error occurred. Please try again later.');
            setIsLoading(false);
        }
    };

    return (
        <div className="add-student-container">
            <Loader isLoading={isLoading} />
            <form onSubmit={handleSubmit}>
                <h2 className="title">ADD SERVICE</h2>
                <div className="form-group p-4">
                    <label htmlFor="specialityName">Service Name:</label>
                    <input
                        type="text"
                        id="specialityName"
                        value={specialityName}
                        onChange={(e) => setSpecialityName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <BoxImageUploader profileImage={specialityImage} setProfileImage={setSpecialityImage} />
                </div>
                <div className="btn-container">
                    <button type="submit" className='submit-btn'>Add Service</button>
                </div>
            </form>
        </div>
    );
};

export default AddSpeciality;
