import { createSlice } from "@reduxjs/toolkit";

// Define initial state for the user profile
const initialState = {
    userProfile: {}, // Initial user profile data
    // Add other user profile state variables here if needed
};

// Define the user profile slice
export const userProfile = createSlice({
    name: "userProfile",
    initialState,
    reducers: {
        // Action to update user profile
        updateUserProfile: (state, action) => {
            state.userProfile = action.payload; // Set user profile to the payload value
        },
        // Add other actions to update user profile state here if needed
    },
});

// Export actions
export const { updateUserProfile } = userProfile.actions;

// Selector to access user profile state
export const selectUserProfile = (state) => state.userProfile.userProfile;

// Reducer for user profile slice
export default userProfile.reducer;
