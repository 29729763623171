import React, { useEffect, useState } from 'react';
import './specialities.css'
import { BASE_URL } from '../../../constants';
import Loader from '../../Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const SpecialityList = () => {

    const navigate = useNavigate();

    const userToken = localStorage.getItem('frotaToken');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [specialities, setSpecialities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteId, setDeleteId] = useState(null)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const fetchSpecialities = async () => {
            try {
                const response = await fetch(`${BASE_URL}speciality`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                });
                if (response.ok) {
                    const json = await response.json();
                    setSpecialities(json.data);
                } else {
                    console.error('Failed to fetch specialities:', response.statusText);
                }
            } catch (error) {
                console.error('An error occurred while fetching specialities:', error);
            }
            setIsLoading(false);
        };

        fetchSpecialities();
    }, [refresh]);

    const handleEditSpeciality = (id) => {
        navigate(`/update-speciality/${id}`)
        console.log('Edit speciality with ID:', id);
    };

    const handleDeleteSpeciality = async (id) => {
        try {
            if (!id) {
                console.error('Invalid speciality ID');
                return;
            }

            const response = await fetch(`${BASE_URL}speciality/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });

            if (response.ok) {
                toast.success('Speciality Deleted.');
                setRefresh(!refresh);
                setIsDeleteModalOpen(false);
            } else {
                console.error('Failed to delete speciality:', response.status, response.statusText);
                toast.error('Failed to delete speciality. Please try again.');
            }
        } catch (error) {
            console.error('An error occurred while deleting speciality:', error);
            toast.error('An unexpected error occurred. Please try again later.');
        }
    };

    return (
        <div className="student-list-container">
            <h2 className='title'>SERVICES LIST</h2>
            {isLoading ? (
                <Loader isLoading={isLoading} />
            ) : (
                <table className="speciality-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Thumbnail</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {specialities?.map((speciality) => (
                            <tr key={speciality.id}>
                                <td>{speciality.id}</td>
                                <td>{speciality.name}</td>
                                <td><img src={speciality.mediaUrl} height={50} alt="Not Available" /></td>
                                <td>
                                    <div className="btn-container">
                                        <button className='edit-btn' onClick={() => handleEditSpeciality(speciality.id)}><FontAwesomeIcon icon={faPen} /> Edit</button>
                                        <button className='delete-btn' onClick={() => { setDeleteId(speciality.id); setIsDeleteModalOpen(true) }}>
                                            <span className='me-1'><FontAwesomeIcon icon={faTrash} /></span>Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <ConfirmationModal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                onConfirm={() => handleDeleteSpeciality(deleteId)}
                message="Are you sure you want to delete this Speciality?"
            />
        </div>
    );
};

export default SpecialityList;
