import React, { useEffect, useState } from 'react'
import './coach.css'
import SharedCalendar from '../SharedCalendar/SharedCalendar'
import { Link } from 'react-router-dom'
import RequestToApprove from './RequestToApprove/RequestToApprove'
import { BASE_URL } from '../../constants'
import { useSelector } from 'react-redux'
import { selectRefreshCoach } from '../../Store/App/Coach/coach'
import { convertTo12HourFormat } from '../Admin/Statistics/AppointmentsStatsList'

const Coach = () => {

  const userToken = localStorage.getItem('frotaToken');
  const refreshCoach = useSelector(selectRefreshCoach)

  // const requests = [{
  //   studentName: 'Amelia',
  //   time: '10:00-11:00',
  //   date: '01.02.2024',
  // },
  // {
  //   studentName: 'Joe',
  //   time: '12:00-01:00',
  //   date: '01.02.2024',
  // },
  // {
  //   studentName: 'Sohaib',
  //   time: '09:00-12:00',
  //   date: '01.02.2024',
  // }];

  const [requests, setRequests] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const fetchPendingRequests = async () => {
    try {
      const response = await fetch(`${BASE_URL}coach/appointments`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const json = await response.json();
      const appointments = json?.data || [];

      // console.log(appointments)

      const pendingAppointments = appointments
        .filter(appointment => appointment.status === 'pending' || appointment.status === 'PENDING')
        .map(appointment => ({
          id: appointment.id,
          studentName: appointment?.student?.name || '',
          specialityName: appointment?.speciality?.name,
          time: `${convertTo12HourFormat(appointment.startTime)} - ${convertTo12HourFormat(appointment.endTime)}`,
          date: `${appointment.scheduledAt}`
        }));

      setRequests(pendingAppointments);
      // console.log('Pending Appointments: ', pendingAppointments)
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchPendingRequests()
  }, [refresh, refreshCoach])

  return (
    <div className='coach-dashboard-container'>

      <div className="coach-head-btns">
        <Link to={'/my-bookings'}>
          <button className='btn-2'>My Bookings</button>
        </Link>
        <Link to={'/coach-statistics'}>
          <button className='btn-1'>Statistics</button>
        </Link>
      </div>

      <div className="requests-container">
        <h2 className='title'>REQUESTS TO APPROVE</h2>
        <div className="requests-to-approve">
          {requests.length > 0 && requests.map((request, index) => (
            <RequestToApprove key={index} request={request} refresh={refresh} setRefresh={setRefresh} />
          ))}
          {requests.length <= 0 && (<div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '20px 0'
          }}><p style={{ textAlign: 'center', margin: 0 }}>No Request Available</p></div>)}
        </div>
      </div>

      <SharedCalendar />
    </div>
  )
}

export default Coach