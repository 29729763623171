import React from 'react';
import './loader.css'; // Import your CSS file for styling

const Loader = ({ isLoading }) => {
  return (
    <div className={`loader-overlay ${isLoading ? 'active' : ''}`}>
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
