import React, { useEffect, useState } from 'react';
import './profile.css';
import { faPen, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASE_URL } from '../../constants';
import Loader from '../../Components/Loader/Loader';
import ImageUploader from '../../Components/ImageUploader/ImageUploader';
import { toast } from 'react-toastify';
import ChangePassword from './ChangePassword';

const Profile = () => {
  const userToken = localStorage.getItem('frotaToken');
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isImageEditing, setIsImageEditing] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [showPage, setShowPage] = useState('user-profile');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleImageSaveClick = async () => {
    setIsLoading(true);
    const formData = new FormData();

    if (profileImage) {
      formData.append('image', profileImage);
    }

    try {
      const response = await fetch(`${BASE_URL}users/upload-avatar`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${userToken}`
        },
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          toast.success('Image Updated Successfully.');
          getUserProfile();
          setIsLoading(false);
          setIsImageEditing(false);
        } else {
          // Handle failure with a non-successful response
          toast.error("Error Uploading Image. Please Try Again.");
          setIsLoading(false);
        }
      } else {
        // Handle failure with a non-OK response status
        const error = await response.json();
        toast.error("Error Uploading Image. Please Try Again.");
        console.error('Image Uploading failed:', error);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle unexpected errors
      toast.error("Error Uploading Image. Please Try Again.");
      console.error('An error occurred:', error);
      setIsLoading(false);
    }
  };


  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}users/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          name: userData.name,
          phone: userData.phone,
          address: userData.address,
        }),
      });

      if (response.ok) {
        toast.success("Profile Updated Successfully");
        setIsEditing(false);
      } else {
        // Handle error
        toast.error('Error Updating Profile.')
        console.error("Failed to update profile:", response.statusText);
      }
    } catch (error) {
      toast.error("Error Updating Profile.");
      console.error("An error occurred while updating profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const getUserProfile = async () => {
    try {
      const response = await fetch(`${BASE_URL}users/me`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });

      if (response.ok) {
        const json = await response.json();
        setUserData(json.data);
        // console.log(json.data)
      } else {
        console.error('Failed to fetch user profile:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching user profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className='profile-main-container'>
      <Loader isLoading={isLoading} />
      <div className="title-container">
        <h2 className="title">USER PROFILE</h2>
      </div>
      {userData && (
        <div className="profile-container">
          <div className="sidebar">
            <button className={showPage === 'user-profile' ? 'active-tab' : ''} onClick={() => setShowPage('user-profile')}>Profile Details</button>
            <button className={showPage === 'change-password' ? 'active-tab' : ''} onClick={() => setShowPage('change-password')}>Change Password</button>
            {/* <button onClick={() => console.log("Change Profile Picture view")}>Change Profile Picture</button> */}
          </div>
          <div className="right-container">

            {showPage === 'user-profile' && (

              <div className="profile">
                <div className="image-container">
                  {isImageEditing ? (
                    <>
                      <ImageUploader profileImage={userData.avatar} setProfileImage={setProfileImage} />
                      <div className="edit-buttons m-auto">
                        <button className="m-auto mx-1" onClick={handleImageSaveClick}>Save</button>
                        <button className='m-auto mx-1' onClick={() => setIsImageEditing(false)}>Cancel</button>
                      </div>
                    </>
                  ) : (
                    <>
                      {!userData.avatar ? (
                        <div className='no-profile'>
                          <FontAwesomeIcon icon={faUser} size='xl' />
                        </div>
                      ) : (
                        <img src={userData.avatar} onLoad={() => setIsImageLoading(false)} alt='' />
                      )}
                      {/* {isImageLoading && <SmallLoader isLoading={isLoading} />} */}
                      <button className='edit-button m-auto my-3' onClick={() => setIsImageEditing(true)}>Change Picture</button>
                    </>
                  )}
                </div>

                <div className="profile-info">
                  <div>
                    <label>Name:</label>
                    {isEditing ? (
                      <input type="text" name="name" value={userData.name} onChange={handleChange} />
                    ) : (
                      <span>{userData.name}</span>
                    )}
                  </div>
                  <div>
                    <label>Email:</label>
                    {isEditing ? (
                      <input type="email" name="email" value={userData.email} disabled />
                    ) : (
                      <span>{userData.email}</span>
                    )}
                  </div>
                  <div>
                    <label>Phone:</label>
                    {isEditing ? (
                      <input type="text" name="phone" value={userData.phone} onChange={handleChange} />
                    ) : (
                      <span>{userData.phone}</span>
                    )}
                  </div>
                  <div>
                    <label>Address:</label>
                    {isEditing ? (
                      <input type="text" name="address" value={userData.address} onChange={handleChange} />
                    ) : (
                      <span>{userData.address}</span>
                    )}
                  </div>
                </div>
                {isEditing ? (
                  <div className="edit-buttons">
                    <button onClick={handleSaveClick}>Save</button>
                    <button onClick={handleCancelClick}>Cancel</button>
                  </div>
                ) : (
                  <button className='edit-button mx-auto my-2' onClick={handleEditClick}>
                    <span className='me-1'><FontAwesomeIcon icon={faPen} /></span>Edit Profile</button>
                )}
              </div>
            )}

            {showPage === 'change-password' && (
              <ChangePassword setIsLoading={setIsLoading} />
            )}

          </div>

        </div>
      )}

    </div>
  );
};

export default Profile;
