import React from 'react'
import Student from '../../Components/Student/Student'

const StudentDashboard = () => {

    return (
        <>
            <Student />
        </>
    )
}

export default StudentDashboard;