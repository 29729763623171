import React, { useEffect, useState } from 'react';
import './imageUploader.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'

const ImageUploader = ({ profileImage, setProfileImage }) => {
    const [image, setImage] = useState(null);

    useEffect(() => {
        setImage(profileImage)
    }, [profileImage])

    // Function to handle file input change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
                // setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImage(null);
        }
    };

    return (
        <div className="image-container">
            {/* Display uploaded image if available */}
            {!image ?
                <div className='no-profile'>
                    <input type="file" accept="image/*" onChange={handleFileChange} />
                    <FontAwesomeIcon icon={faUser} size='xl' />
                </div>
                : <img src={image} alt="Uploaded" />}
            <label className="custom-file-upload">
                <input type="file" accept="image/*" onChange={handleFileChange} />
                Upload Picture
            </label>
        </div>
    );
};

export default ImageUploader;
