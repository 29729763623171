import { createSlice } from "@reduxjs/toolkit";

// Define initial state for the header
const initialState = {
    credits: 0, // Initial credits value
    // Add other header state variables here if needed
};

// Define the header slice
export const header = createSlice({
    name: "header",
    initialState,
    reducers: {
        // Action to update credits
        updateCredits: (state, action) => {
            state.credits = action.payload; // Set credits to the payload value
        },
        // Add other actions to update header state here if needed
    },
});

// Export actions
export const { updateCredits } = header.actions;

// Selectors to access header state
export const selectCredits = (state) => state.header.credits;

// Reducer for header slice
export default header.reducer;
