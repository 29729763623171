import React from 'react';
import Modal from 'react-modal';
import './../AppointmentModal/appointmentModal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const AppointmentDetailsModal = ({ isOpen, onClose, appointment }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Booking Details"
            className="AppointmentModal"
            overlayClassName="AppointmentModal-overlay"
            ariaHideApp={false}
        >
            <div className="modal-header" style={{ justifyContent: 'center' }}>
                <h2>Booking Details</h2>
            </div>
            <div className="modal-body mb-2" style={{ padding: '16px 0', borderRadius: '10px', background: 'var(--light-grey)' }}>
                <div className="image-container">
                    {!appointment?.student?.avatar ?
                        <div className='no-profile' style={{ width: '80px', height: '80px' }}>
                            <FontAwesomeIcon icon={faUser} size='2xs' />
                        </div>
                        : <img src={appointment.student.avatar} alt="Uploaded" style={{ width: '80px', height: '80px' }} />}
                </div>

                <p><strong>Student:</strong> {appointment.student?.name}</p>
                <p><strong>Class:</strong> {appointment.title}</p>
                <p><strong>Coach:</strong> {appointment.coach?.name}</p>
                <p><strong>Date:</strong> {appointment.date}</p>
                <p><strong>Time:</strong> {appointment.time}</p>
                <p><strong>Status:</strong> <span style={{
                    textTransform: 'uppercase',
                    color: '#00da0d',
                    fontWeight: 600,
                    fontStyle: 'italic'
                }}>{appointment.status}</span></p>
                {/* <img src={appointment.student?.avatar} alt="" /> */}
                {/* Add more appointment details here */}
            </div>
            <button onClick={onClose} className="close-button">Close</button>
        </Modal>
    );
};

export default AppointmentDetailsModal;
