import React from 'react'
import Coach from './../../Components/Coach/Coach'

const CoachDashboard = () => {

    return (
        <>
            <Coach />
        </>
    )
}

export default CoachDashboard;