import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import Loader from '../Components/Loader/Loader';


const StudentRoute = ({ element, studentOnly, userRole }) => {

    // Render the component based on user role and route requirements
    return (
        userRole === null ? (
            <Loader isLoading={true} />
        ) : (
            // If user is authenticated and has admin access or not admin-only route, render the component
            (userRole === 'student' && studentOnly) || !studentOnly ? (
                element
            ) : (
                // If user is not authenticated or doesn't have admin access, redirect to login page
                <Navigate to="/" replace />
            )
        )

    );
};

export default StudentRoute;