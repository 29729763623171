import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from '../Components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from '../Store/App/userProfile';
import { selectRefreshCoach, updateRefreshCoach } from '../Store/App/Coach/coach';
import { BASE_URL } from '../constants';
import io from 'socket.io-client'
import { toast } from 'react-toastify';

const CoachRoute = ({ element, coachOnly, userRole }) => {

    const userProfile = useSelector(selectUserProfile);
    const refreshCoach = useSelector(selectRefreshCoach);

    const dispatch = useDispatch();

    useEffect(() => {
        // Connect to the socket server
        const socket = io(`${BASE_URL}`); 
        // Socket event listeners
        socket.on('connect', () => {
            console.log('Connected to socket server');
        });

        socket.emit('new user', userProfile.id);

        // Join room number 7
        socket.emit('join', { name: 'Arif', room: 7 });


        socket.on('appointment', (data) => {
            // console.log('Received appointment event:', data);
            toast('Received Appointment Request.');
            dispatch(updateRefreshCoach(!refreshCoach))
        });

        // Clean up the socket connection when the component unmounts
        return () => {
            socket.disconnect(userProfile.id);
        };
    }, [userProfile]);

    // Render the component based on user role and route requirements
    return (
        userRole === null ? (
            <Loader isLoading={true} />
        ) : (
            // If user is authenticated and has admin access or not admin-only route, render the component
            (userRole === 'coach' && coachOnly) || !coachOnly ? (
                element
            ) : (
                // If user is not authenticated or doesn't have admin access, redirect to login page
                <Navigate to="/" replace />
            )
        )
    );
};

export default CoachRoute;