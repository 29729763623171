import React, { useEffect, useState } from 'react';
import './admin.css'
import ImageUploader from '../ImageUploader/ImageUploader';
import { BASE_URL, passwordPattern } from '../../constants';
import Loader from '../Loader/Loader';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faPen } from '@fortawesome/free-solid-svg-icons';

const UpdateStudent = () => {

    const userToken = localStorage.getItem('frotaToken')

    const navigate = useNavigate();

    const { id } = useParams();
    // State variables to store form inputs
    const [isLoading, setIsLoading] = useState(false);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [studentData, setStudentData] = useState(null)
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [credits, setCredits] = useState('');
    const [clientDescription, setClientDescription] = useState('');
    const [profileImage, setProfileImage] = useState(null);

    const [isCreditsEdit, setIsCreditsEdit] = useState(false);

    const [viewPassword, setViewPassword] = useState(false);

    useEffect(() => {
        const getStudentData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${BASE_URL}admin/users?type=student`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                });
                if (response.ok) {
                    const json = await response.json();
                    const studentRecord = json?.data?.find(record => record.id === parseInt(id, 10));
                    if (studentRecord) {
                        setStudentData(studentRecord);
                        setName(studentRecord.name);
                        setAddress(studentRecord.address);
                        setPhone(studentRecord.phone);
                        setEmail(studentRecord.email);
                        setCredits(studentRecord.credits);
                        setClientDescription(studentRecord.description);
                        setProfileImage(studentRecord.avatar)
                    }
                } else {
                    throw new Error('Failed to fetch student data');
                }
            } catch (error) {
                console.error('Error fetching student data:', error);
                toast.error('Failed to fetch student data');
            } finally {
                setIsLoading(false);
            }
        };
        getStudentData();
    }, [id, userToken]);

    const handleUpdateCredits = async () => {
        if (credits === studentData.credits) {
            toast.success('Credits Updated.')
            setIsCreditsEdit(false);
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}admin/credits/update`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'application/json' // Make sure to include this header for JSON requests
                },
                body: JSON.stringify({
                    id: id,
                    credits: credits
                })
            });

            if (response.ok) {
                const data = await response.json();
                toast.success('Credits Updated.');
                setIsCreditsEdit(false);
                // console.log('Credits Updated Successfully:', data);
            } else {
                const error = await response.json();
                console.error('Failed to update credits:', error);
                toast.error('Error Updating Credits. Please Try Again.')
            }
        } catch (error) {
            console.error('An error occurred while updating credits:', error);
        }

        setIsLoading(false);
    }

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password) {
            const isValidPassword = passwordPattern.test(password);
            if (!isValidPassword) {
                toast.error('Password must contain at least One Uppercase letter, one Lowercase letter, one Number, and one Special Character, and be at least 8 characters long.')
                return;
            }
        }

        setIsLoading(true);

        const formData = new FormData();

        if (name && name !== studentData.name) {
            formData.append('name', name);
        }
        if (phone && phone !== studentData.phone) {
            formData.append('phone', phone);
        }
        if (email && email !== studentData.phone) {
            formData.append('email', email);
        }
        if (password) {
            formData.append('password', password);
        }
        if (address && address !== studentData.address) {
            formData.append('address', address);
        }
        if (clientDescription && clientDescription !== studentData.description) {
            formData.append('description', clientDescription);
        }
        if (profileImage && profileImage !== studentData.avatar) {
            formData.append('image', profileImage);
        }

        try {
            const response = await fetch(`${BASE_URL}users/update/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${userToken}`
                },
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    toast.success('Student Updated Successfully.');
                    setIsLoading(false);

                    // alert(data.message);
                }
                // console.log('Student Update successful:', data);
                // Handle successful sign-up, such as displaying a success message
                return data; // Return data if further processing is needed
            } else {
                // Handle sign-up failure
                const error = await response.json();
                console.error('Student Update failed:', error);
                setIsLoading(false);

                // Handle error, such as displaying an error message
                throw new Error('Student Update failed'); // Throw error for further handling
            }
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle network errors or other exceptions
            setIsLoading(false);
            throw new Error('An unexpected error occurred'); // Throw error for further handling
        }


        // Clear form fields after submission
        clearForm();
    };

    const handleDeleteStudent = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}admin/remove_user/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${userToken}`
                }
            });

            if (response.ok) {
                toast.success('User deleted successfully.');
                navigate('/student-list')
            } else {
                setIsLoading(false);
                toast.error('Error Deleting Student. Please Try Again.')
                throw new Error('Failed to delete user.');
            }
        } catch (error) {
            console.error('An error occurred while deleting user:', error);
            toast.error('An unexpected error occurred.');
        }
        setIsLoading(false);
    }


    // Function to clear form fields
    const clearForm = () => {
        setName('');
        setAddress('');
        setPhone('');
        setEmail('');
        setClientDescription('');
    };

    return (
        <div className='add-student-container'>
            <Loader isLoading={isLoading} />
            <form onSubmit={handleSubmit}>
                <h2 className='title'>UPDATE STUDENT</h2>
                <div className="form-container">
                    <div className="left-container">
                        <div className='form-field'>
                            <label htmlFor='name'>Name: </label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                placeholder="Enter Student's Name"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='email'>Email: </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                placeholder="Enter Student's Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='phone'>Phone: </label>
                            <input
                                type="text"
                                id="phone"
                                value={phone}
                                placeholder="Enter Phone Number"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='password'>Password: </label>
                            <input
                                type={viewPassword ? 'text' : 'password'}
                                id="password"
                                value={password}
                                placeholder="Enter Student's Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <div className='view-password-icon-container'>
                                <FontAwesomeIcon
                                    icon={viewPassword ? faEye : faEyeSlash}
                                    onClick={() => setViewPassword(!viewPassword)}
                                    className='view-password-icon' />
                            </div>
                        </div>
                        <div className='update-credits'>
                            <div className='form-field' style={{ position: 'relative' }}>
                                <label htmlFor='credits'>Credits: </label>
                                <input
                                    type="number"
                                    id="credits"
                                    value={credits}
                                    placeholder="Enter Credits"
                                    onChange={(e) => setCredits(e.target.value)}
                                    disabled={!isCreditsEdit}
                                />
                            </div>
                            {isCreditsEdit ?
                                <>
                                    <button type='button' className='save-credits' onClick={handleUpdateCredits}>
                                        Update Credits
                                    </button>
                                    <button type='button' className='cancel-credits' onClick={() => setIsCreditsEdit(false)}>
                                        Cancel
                                    </button>
                                </>
                                :
                                <button type='button' className='edit-credits' onClick={() => setIsCreditsEdit(true)}>
                                    <FontAwesomeIcon icon={faPen} size='xs' className='me-1' />Change Credits
                                </button>}

                        </div>


                    </div>
                    <div className="right-container">
                        <ImageUploader profileImage={profileImage} setProfileImage={setProfileImage} />
                    </div>
                </div>

                <div className="bottom-container">
                    <div className='form-field'>
                        <label htmlFor='address'>Address: </label>
                        <input
                            type="text"
                            id="address"
                            value={address}
                            placeholder="Enter Student's Address"
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <div className='form-field'>
                        <label htmlFor='clientDescription'>Client Description: </label>
                        <textarea
                            type="text"
                            id="clientDescription"
                            value={clientDescription}
                            rows={4}
                            placeholder='Enter Some Client Description...'
                            onChange={(e) => setClientDescription(e.target.value)}
                        />
                    </div>

                    <div className="btn-container">
                        <button type="submit" className='submit-btn'>Update Student</button>
                        <button type='button' className='delete-btn' onClick={() => setIsDeleteModalOpen(true)}>Delete Student</button>
                    </div>

                    <ConfirmationModal
                        isOpen={isDeleteModalOpen}
                        onRequestClose={() => setIsDeleteModalOpen(false)}
                        onConfirm={handleDeleteStudent}
                        message="Are you sure you want to delete this student?"
                    />
                </div>

            </form>
        </div>
    )
}

export default UpdateStudent;
