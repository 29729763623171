import React from 'react'
import './student.css'
import SharedCalendar from '../SharedCalendar/SharedCalendar'
import { Link } from 'react-router-dom'

const Student = () => {
  return (
    <div className='student-dashboard-container'>
      <div className="coach-head-btns">
        <Link to={'/student-bookings'}>
          <button className='btn-2'>My Bookings</button>
        </Link>
        <Link to={'/student-statistics'}>
          <button className='btn-1'>Statistics</button>
        </Link>
      </div>

      <SharedCalendar isStudent={true} />
    </div>
  )
}

export default Student