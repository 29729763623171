import React, { useState } from 'react';
import Modal from 'react-modal';

const AddCommentModal = ({ status, isOpen, onClose, onSubmit }) => {
    const [comment, setComment] = useState('');

    const handleChange = (event) => {
        setComment(event.target.value);
    };

    const handleSubmit = () => {
        onSubmit(status, comment);
        setComment('');
        onClose();
    };

    const titleCase = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel={`Confirm ${titleCase(status)}`}
                className="AppointmentModal"
                overlayClassName="AppointmentModal-overlay"
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <h2 style={{ textAlign: 'center' }}>Confirm {titleCase(status)}</h2>
                    <textarea
                        rows="4"
                        cols="40"
                        value={comment}
                        onChange={handleChange}
                        placeholder="Enter your comment here..."
                        style={{ color: 'var(--text-color)' }}
                    />
                    <div style={{ display: 'flex' }}>
                        <button onClick={onClose} className="close-button close-comment-modal me-2" style={{ background: 'var(--cancel-color)', color: '#000' }}>Close</button>
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddCommentModal;
