import React from 'react';
import { Navigate } from 'react-router-dom';
import Loader from '../Components/Loader/Loader';

const AdminRoute = ({ element, adminOnly, userRole }) => {

    // Render the component based on user role and route requirements
    return (
        // If userRole is not loaded yet, render a loading indicator
        userRole === null ? (
            // <div>Loading...</div>
            <Loader isLoading={true}/>
        ) : (
            // If user is authenticated and has admin access or not admin-only route, render the component
            (userRole === 'admin' && adminOnly) || !adminOnly ? (
                element
            ) : (
                // If user is not authenticated or doesn't have admin access, redirect to login page
                <Navigate to="/admin-login" replace />
            )
        )
    );
};

export default AdminRoute;
