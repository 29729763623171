import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectCredits, updateCredits } from '../../Store/App/Header';
import Loader from '../Loader/Loader';

const UserNavLinks = ({ onClick, userRole }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const credits = useSelector(selectCredits);

    const [isLoading, setIsLoading] = useState(false);

    const handleUserLogout = async () => {

        const response = await fetch(`${BASE_URL}users/logout`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('frotaToken')}`
            }
        })

        if (response.ok) {

        }

        localStorage.removeItem('frotaToken');
        localStorage.removeItem('refreshToken');
        sessionStorage.removeItem('user');
        setTimeout(() => {
            navigate('/')
        }, 100);
    }

    useEffect(() => {
        const getUserProfile = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${BASE_URL}users/me`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('frotaToken')}`
                    }
                });

                if (response.ok) {
                    const json = await response.json();
                    dispatch(updateCredits(json?.data?.credits))

                } else {
                    console.error('Failed to fetch user profile:', response.statusText);
                }
            } catch (error) {
                console.error('An error occurred while fetching user profile:', error);
                setIsLoading(false)
            } finally {
                setIsLoading(false)
            }
        };

        getUserProfile();
    }, [])


    return (
        <>
            <Loader isLoading={isLoading} />
            <li className="nav-item" onClick={onClick}>
                {userRole === 'student' && (<Link to={'/student-dashboard'} className="nav-link">DASHBOARD</Link>)}
                {userRole === 'coach' && (<Link to={'/coach-dashboard'} className="nav-link">DASHBOARD</Link>)}
            </li>
            <li className="nav-item" onClick={onClick}>
                <Link to={'/profile'} className="nav-link">PROFILE</Link>
            </li>
            <li className="nav-item" onClick={onClick}>
                <Link className="nav-link" onClick={handleUserLogout}>LOGOUT</Link>
            </li>
            <li className="nav-item" onClick={onClick}>
                <p className="nav-link" style={{
                    margin: 0,
                    borderRadius: '50px',
                    background: '#ffeeee'
                }}>CREDITS: {credits}</p>
            </li>
        </>
    );
};

export default UserNavLinks;
