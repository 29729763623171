// ** Import Libraries
import { configureStore } from '@reduxjs/toolkit'
import header from './App/Header'
import userProfile from './App/userProfile'
import coach from './App/Coach/coach'

export const store = configureStore({
    reducer: {
        header,
        userProfile,
        coach
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    //     serializableCheck: {
    //         //   ignoredActions: ['canvas/updateCanvasContainer', 'canvas/updateSelectedCanvas', 'canvas/updateSelectedObject'],
    //         //   ignoredPaths: ['canvas.canvasContainer', 'canvas.selectedCanvas', 'canvas.selectedObject']
    //     }
    // })
})

window.store = store