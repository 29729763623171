import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from '../Pages/Login/Login';
import Profile from '../Pages/Profile/Profile';
import AdminDashboard from '../Pages/AdminDashboard/AdminDashboard';
import AddStudent from '../Components/Admin/AddStudent';
import AddCoach from '../Components/Admin/AddCoach';
import Header from '../Components/Header/Header';
import StudentCoachList from '../Components/Admin/StudentCoachList/StudentCoachList';
import CoachDashboard from '../Pages/CoachDashboard/CoachDashboard';
import StudentDashboard from '../Pages/StudentDashboard/StudentDashboard';
import AdminRoute from './AdminRoute';
import ClassesPerCoach from '../Components/Admin/Statistics/ClassesPerCoach';
import ClassesPerStudent from '../Components/Admin/Statistics/ClassesPerStudent';
import EarnedMoney from '../Components/Admin/Statistics/EarnedMoney';
import CoachRoute from './CoachRoute';
import StudentRoute from './StudentRoute';
import { BASE_URL } from '../constants';
import Loader from '../Components/Loader/Loader';
import UpdateStudent from '../Components/Admin/UpdateStudent';
import AddSpeciality from '../Components/Admin/Specialities/AddSpeciality';
import SpecialityList from '../Components/Admin/Specialities/SpecialityList';
import UpdateSpeciality from '../Components/Admin/Specialities/UpdateSpeciality';
import UpdateCoach from '../Components/Admin/UpdateCoach';
import CoachBookings from '../Components/Coach/CoachBookings/CoachBookings';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile, updateUserProfile } from '../Store/App/userProfile';
import StudentBookings from '../Components/Student/StudentBookings';
import CoachStatistics from '../Components/Coach/CoachStatistics';
import ResetPassword from '../Pages/Login/ResetPassword';
import ForgotPassword from '../Pages/Login/ForgotPassword';

const AppRoutes = () => {

    const dispatch = useDispatch();
    const userToken = localStorage.getItem('frotaToken');
    const [userRole, setUserRole] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    const userProfile = useSelector(selectUserProfile);

    const getUserProfile = async () => {
        try {
            const response = await fetch(`${BASE_URL}users/me`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });

            if (response.ok) {
                const json = await response.json();
                if (json.data) {
                    dispatch(updateUserProfile(json.data))
                }
                // console.log('work')
            } else {
                console.error('Failed to fetch user profile:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching user profile:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const getUserRole = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${BASE_URL}auth/fetch_role`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('frotaToken')}`
                    }
                });

                if (response.ok) {
                    const json = await response.json();
                    const { roles } = json.data;
                    if (roles?.length > 0) {
                        setUserRole(roles[0]);
                        return (<Navigate to={`${roles[0]}-dashboard`} />)
                    }
                } else {
                    setUserRole('null');
                    console.error('Error fetching user role:', response.statusText);
                    return (<Navigate to={'/'} />)
                }
            } catch (error) {
                console.error('An error occurred:', error);
            } finally {
                setIsLoading(false);
            }
        };

        getUserRole();

        if (!userProfile || Object.entries(userProfile)?.length === 0) {
            getUserProfile()
        }

        const handleStorageChange = () => {
            if (localStorage.getItem('frotaToken')) {
                getUserRole();
            }
            console.log("token changes")
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <Router>
            <Loader isLoading={isLoading} />

            {!isLoading && (
                <>
                    <Header userRole={userRole} />

                    <div className='main-container'>
                        <Routes>
                            {/* <Route path="/" element={userRole && (userRole === 'student' || userRole === 'coach') ? <Navigate to={`${userRole}-dashboard`} /> : <Login />} /> */}
                            {/* <Route path="/admin-login" element={userRole === null || userRole === 'null' ? <Login isAdmin={true} /> : <Navigate to={`/admin-dashboard`} />} /> */}

                            <Route path="/" element={<Login />} />
                            <Route path="/admin-login" element={<Login isAdmin={true} />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/reset-password" element={<ResetPassword />} />

                            <Route path="/student-dashboard" element={<StudentRoute userRole={userRole} element={<StudentDashboard />} studentOnly />} />
                            <Route path="/student-bookings" element={<StudentRoute userRole={userRole} element={<StudentBookings />} studentOnly />} />
                            <Route path="/student-statistics" element={<StudentRoute userRole={userRole} element={<CoachStatistics />} studentOnly />} />

                            <Route path="/coach-dashboard" element={<CoachRoute userRole={userRole} element={<CoachDashboard />} coachOnly />} />
                            <Route path="/my-bookings" element={<CoachRoute userRole={userRole} element={<CoachBookings />} coachOnly />} />
                            <Route path="/coach-statistics" element={<CoachRoute userRole={userRole} element={<CoachStatistics />} coachOnly />} />

                            <Route path="/classes-per-coach" element={<AdminRoute userRole={userRole} element={<ClassesPerCoach />} adminOnly />} />
                            <Route path="/classes-per-student" element={<AdminRoute userRole={userRole} element={<ClassesPerStudent />} adminOnly />} />
                            <Route path="/earned-money" element={<AdminRoute userRole={userRole} element={<EarnedMoney />} adminOnly />} />
                            <Route path="/admin-dashboard" element={<AdminRoute userRole={userRole} element={<AdminDashboard />} adminOnly />} />
                            <Route path="/add-student" element={<AdminRoute userRole={userRole} element={<AddStudent />} adminOnly />} />
                            <Route path="/update-student/:id" element={<AdminRoute userRole={userRole} element={<UpdateStudent />} adminOnly />} />
                            <Route path="/add-coach" element={<AdminRoute userRole={userRole} element={<AddCoach />} adminOnly />} />
                            <Route path="/update-coach/:id" element={<AdminRoute userRole={userRole} element={<UpdateCoach />} adminOnly />} />
                            <Route path="/student-list" element={<AdminRoute userRole={userRole} element={<StudentCoachList title="Student" />} adminOnly />} />
                            <Route path="/coach-list" element={<AdminRoute userRole={userRole} element={<StudentCoachList title="Coach" />} adminOnly />} />
                            <Route path="/add-speciality" element={<AdminRoute userRole={userRole} element={<AddSpeciality />} adminOnly />} />
                            <Route path="/speciality-list" element={<AdminRoute userRole={userRole} element={<SpecialityList />} adminOnly />} />
                            <Route path="/update-speciality/:id" element={<AdminRoute userRole={userRole} element={<UpdateSpeciality />} adminOnly />} />

                            {userRole === null || userRole === 'null' && (<Route path="*" element={<Navigate to="/" />} />)}
                            {/* {userRole === 'admin' && (<Route path="*" element={<Navigate to="/admin-dashboard" />} />)} */}
                            {/* {userRole === "student" && (<Route path="*" element={<Navigate to="/student-dashboard" />} />)} */}
                            {/* {userRole === 'coach' && (<Route path="*" element={<Navigate to="/coach-dashboard" />} />)} */}
                        </Routes>
                    </div>
                </>
            )}
        </Router>
    );

    // return (
    //     <Router>
    //         <Loader isLoading={isLoading} />
    //         {!isLoading && (
    //             <>
    //                 <Header userRole={userRole} />
    //                 <div className='main-container'>
    //                     <Routes>
    //                         <Route path="/" element={<Login />} />
    //                         <Route path="/admin-login" element={<Login isAdmin={true} />} />
    //                         <Route path="/profile" element={<Profile />} />


    //                         {userRole === 'null' && <Route path="*" element={<Navigate to="/" />} />}
    //                         {userRole === 'student' && <Route path="/student-dashboard" element={<StudentDashboard />} />}
    //                         {userRole === 'coach' && <Route path="/coach-dashboard" element={<CoachDashboard />} />}
    //                         {userRole === 'admin' && <Route path="/admin-dashboard" element={<AdminDashboard />} />}
    //                         {userRole === 'admin' && <Route path="/add-student" element={<AddStudent />} />}
    //                         {userRole === 'admin' && <Route path="/add-coach" element={<AddCoach />} />}
    //                         {userRole === 'admin' && <Route path="/student-list" element={<StudentCoachList title="Student" />} />}
    //                         {userRole === 'admin' && <Route path="/coach-list" element={<StudentCoachList title="Coach" />} />}
    //                         {userRole === 'admin' && <Route path="/classes-per-coach" element={<ClassesPerCoach />} />}
    //                         {userRole === 'admin' && <Route path="/classes-per-student" element={<ClassesPerStudent />} />}
    //                         {userRole === 'admin' && <Route path="/earned-money" element={<EarnedMoney />} />}
    //                     </Routes>
    //                 </div>
    //             </>
    //         )}
    //     </Router>
    // );
};

export default AppRoutes;
