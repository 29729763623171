import React, { useState } from 'react';
import './login.css';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL, passwordPattern } from '../../constants';
import Loader from '../../Components/Loader/Loader';
import { toast } from 'react-toastify';
import { updateUserProfile } from '../../Store/App/userProfile';
import { useDispatch } from 'react-redux';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ResetPassword = ({ isAdmin }) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

    const currentUrl = window.location.href;

    const url = new URL(currentUrl);

    const token = url.searchParams.get('token');

    const handleUserResetPassword = async () => {
        setIsLoading(true);

        const isValidPassword = passwordPattern.test(password);
        if (!isValidPassword) {
            toast.error('Password must contain at least One Uppercase letter, one Lowercase letter, one Number, and one Special Character, and be at least 8 characters long.')
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}auth/password/reset?token${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password: password
                }),
            });

            if (response.ok) {
                const data = await response.json();
                // console.log('Reset successful:', data);
                toast.success(data.message);
            } else {
                // Handle login failure
                const error = await response.json();
                toast.error(error.message)
            }
            setIsLoading(false)
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An unexpected error occurred. Please try again later.');
            setIsLoading(false)
        }
    }

    const handleAdminResetPassword = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}auth/admin/sign_in`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password: password,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                // console.log('Login successful:', data);
                const { token, refreshToken } = data;
                const { user } = data.data;

                dispatch(updateUserProfile(user))

                // Store tokens and user data securely
                localStorage.setItem('frotaToken', token);
                localStorage.setItem('refreshToken', refreshToken);
                window.dispatchEvent(new Event("storage"));
                // sessionStorage.setItem('user', JSON.stringify(user)); // Use sessionStorage for user data

                // Redirect or navigate to dashboard after a short delay (optional)
                navigate('/admin-dashboard');
            } else {
                // Handle login failure
                const error = await response.json();
                console.error('Login failed:', error);
                toast.error('Login failed. Please check your credentials.');
                setIsLoading(false)
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An unexpected error occurred. Please try again later.');
            setIsLoading(false)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            toast.error('Password and Current Password is not Same.')
        }

        if (!password) {
            toast.error('Enter a Password.')
            return;
        }

        if (!confirmPassword) {
            toast.error('Enter a Confirm Password.')
            return;
        }

        if (isAdmin) {
            handleAdminResetPassword();
        } else {
            handleUserResetPassword();
        }

    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="page-content">
                <div className="login-container" style={{ opacity: 1 }}>
                    <div className="title-container">
                        <h2 className="title">RESET PASSWORD</h2>
                    </div>
                    <div className="form-container">
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <div className="field field-password field-user_password field-password field-type_password" data-key="user_password">
                                <div className="field-label">
                                    <label htmlFor="user_password">New Password</label>
                                </div>
                                <div className="field-area">
                                    <input
                                        className="form-field valid"
                                        type={viewPassword ? 'text' : 'password'}
                                        name="user_password"
                                        id="user_password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter New Password"
                                        aria-invalid="false"
                                    />
                                    <div className='view-password-icon-container'>
                                        <FontAwesomeIcon
                                            icon={viewPassword ? faEye : faEyeSlash}
                                            onClick={() => setViewPassword(!viewPassword)}
                                            className='view-password-icon' />
                                    </div>
                                </div>
                            </div>
                            <div className="field field-password field-user_password field-password field-type_password" data-key="user_password">
                                <div className="field-label">
                                    <label htmlFor="user_password">Confirm New Password</label>
                                </div>
                                <div className="field-area">
                                    <input
                                        className="form-field valid"
                                        type={viewPassword ? 'text' : 'password'}
                                        name="user_password"
                                        id="user_password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm New Password"
                                        aria-invalid="false"
                                    />
                                    <div className='view-password-icon-container'>
                                        <FontAwesomeIcon
                                            icon={viewConfirmPassword ? faEye : faEyeSlash}
                                            onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
                                            className='view-password-icon' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-alt">
                                <div className="login-btn-container">
                                    <button type="submit" className="login-button" id="submit-btn">Reset Password</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;