import React from 'react';
import { BASE_URL } from '../../../constants';
import { toast } from 'react-toastify';

export const convertTo12HourFormat = (time) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(':');

    // Convert hours to 12-hour format
    let hour = parseInt(hours, 10);
    // const meridiem = hour >= 12 ? 'PM' : 'AM';
    // hour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

    // Return the formatted time string
    // return `${hour}:${minutes}${meridiem}`;
    return `${hour}:${minutes}`;
};

const AppointmentsStatsList = ({ title, coach, appointments, month, year, viewAs, refreshStats, setRefreshStats }) => {

    const userToken = localStorage.getItem('frotaToken');

    const handleMarkPaid = async (ids) => {

        try {
            const response = await fetch(`${BASE_URL}admin/appointment_paid`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(ids)
            })

            if (!response.ok) {
                toast.error("Error Marking Appointment as Paid.")
                throw new Error('Error Marking Paid.')
            }

            const json = await response.json();
            // console.log(json);

            toast.success("Appointment Marked as Paid.")

            setRefreshStats(!refreshStats);

        } catch (error) {
            console.error('Error: ', error);
        }


    }

    const handleAllNotPaidRecord = async () => {
        const ids = appointments
            .filter(appointment => !appointment.isPaid)
            .map(appointment => appointment.id);

        // console.log(ids);

        await handleMarkPaid(ids);
    }

    const renderPaidStatus = (appointment) => {
        if (appointment.isPaid) {
            return (
                <span style={{ color: '#3AEB00', fontStyle: 'italic', fontWeight: 600 }}>PAID</span>
            );
        } else {
            if (viewAs === 'Admin') {
                return (
                    <button className='paid-button' onClick={() => handleMarkPaid([appointment?.id])}>Mark as Paid</button>
                );
            } else {
                return (
                    <span style={{ color: '#FF0000', fontStyle: 'italic', fontWeight: 600 }}>UNPAID</span>
                );
            }
        }
    };

    return (
        <div className='appointment-list-container'>
            <h2 className='appintment-list-title'>Stats {coach && 'For ' + coach + " in "} {month && " " + month} {year && " " + year}</h2>
            <div className='total-container'>
                <div className='total-classes'>
                    Total Classes
                    <h1> {appointments?.length} </h1>
                </div>
                {title === 'Coach' && viewAs === 'Admin' && (
                    <div className='total-paid-classes'>
                        Total Paid Classes
                        <h1> {appointments?.filter(appointment => appointment.isPaid).length} </h1>
                    </div>)}
            </div>
            {title === 'Coach' && viewAs === 'Admin' && (
                <div className='mark-all-btn-container'>
                    <button className='mark-all-paid'
                        onClick={handleAllNotPaidRecord}
                        disabled={appointments?.length <= appointments.filter(appointment => appointment.isPaid).length}
                    >Mark All as Paid</button>
                </div>)}
            {/* <div className='total-paid-classes'>
                Total Unpaid Classes
                <h1> {appointments.filter(appointment => !appointment.isPaid).length} </h1>
            </div> */}
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{title === 'Coach' || (title === 'Coach' && viewAs === 'Admin') ? 'Student' : 'Coach'}</th>
                            <th>Class</th>
                            <th>Scheduled At</th>
                            <th>Time</th>
                            {(title === 'Coach' || (title === 'Coach' && viewAs === 'Admin')) && (<th>Paid Status</th>)}
                            {(title === 'Coach' || (title === 'Coach' && viewAs === 'Admin')) && (<th>Paid Time</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {appointments.map((appointment, index) => (
                            <tr key={index}>
                                <td>{appointment?.id}</td>
                                <td>{title === 'Coach' || (title === 'Coach' && viewAs === 'Admin') ? appointment?.student?.name : appointment?.coach?.name}</td>
                                <td>{appointment?.speciality?.name}</td>
                                {/* <td>{appointment?.scheduledAt}</td> */}
                                <td>{appointment?.scheduledAt}</td>
                                <td>{convertTo12HourFormat(appointment?.startTime) + ' - ' + convertTo12HourFormat(appointment?.endTime)}</td>
                                {(title === 'Coach' || (title === 'Coach' && viewAs === 'Admin')) && (<td>{renderPaidStatus(appointment)}</td>)}
                                {(title === 'Coach' || (title === 'Coach' && viewAs === 'Admin')) && (<td>{appointment?.paidTime ? appointment?.paidTime?.split('T')[0] : (<span>Not Paid</span>)}</td>)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {appointments.length === 0 && (<div style={{ textAlign: 'center', padding: '10px' }}>No Classes Available.</div>)}

        </div>
    );
};

export default AppointmentsStatsList;
