import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import './lineChart.css'

const LineChart = ({ data, options }) => {
    const chartRef = useRef(null);
    let chartInstance = null;

    useEffect(() => {
        if (chartRef.current && data) {
            // If a chart instance already exists, destroy it
            if (chartInstance) {
                chartInstance.destroy();
            }

            const ctx = chartRef.current.getContext('2d');
            chartInstance = new Chart(ctx, {
                type: 'line',
                data: data,
                options: options
            });

            return () => {
                // Cleanup function to destroy the chart instance when the component unmounts
                chartInstance.destroy();
            };
        }
    }, [data, options]);

    return (
        <div className="chart-container">
            <canvas ref={chartRef}></canvas>
        </div>
    );
};

export default LineChart;
