import React, { useState } from 'react'
import { BASE_URL, passwordPattern } from '../../constants';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ChangePassword = ({ setIsLoading }) => {

    const userToken = localStorage.getItem('frotaToken');

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [viewPassword, setViewPassword] = useState(false);
    const [viewNewPassword, setViewNewPassword] = useState(false);
    const [viewConfirmNewPassword, setViewConfirmNewPassword] = useState(false);


    const handlePasswordChange = async () => {
        setIsLoading(true);
        if (newPassword !== confirmNewPassword) {
            toast.error('Error: Not Same New Password and Confirm New Password.')
            setIsLoading(false)
            return;
        }

        if (!currentPassword) {
            toast.error('Enter your Current Password.')
            setIsLoading(false)
            return;
        }
        if (!newPassword) {
            toast.error('Enter New Password.')
            setIsLoading(false)
            return;
        }
        if (!confirmNewPassword) {
            toast.error('Enter Confirm New Password.')
            setIsLoading(false)
            return;
        }

        const isValidPassword = passwordPattern.test(newPassword);
        if (!isValidPassword) {
            toast.error('Password must contain at least One Uppercase letter, one Lowercase letter, one Number, and one Special Character, and be at least 8 characters long.')
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}users/resetpassword`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
                body: JSON.stringify({
                    oldPassword: currentPassword,
                    password: newPassword,
                }),
            });

            if (response.ok) {
                toast.success('Password Changed Successfully.')
                console.log("Password reset successful");
            } else {
                const json = await response.json();
                toast.error(json.message);
                console.error("Failed to reset password:", response.statusText);
            }
        } catch (error) {
            toast.error('Error Changing Password.')
            console.error("An error occurred while resetting password:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="password-fields">
            <div className='field'>
                <label htmlFor="">Current Password</label>
                <input
                    type={viewPassword ? 'text' : 'password'}
                    placeholder="Current Password"
                    onChange={(e) => setCurrentPassword(e.target.value)} />
                <div className='view-password-icon-container'>
                    <FontAwesomeIcon
                        icon={viewPassword ? faEye : faEyeSlash}
                        onClick={() => setViewPassword(!viewPassword)}
                        className='view-password-icon' />
                </div>
            </div>
            <div className='field'>
                <label htmlFor="">New Password</label>
                <input
                    type={viewNewPassword ? 'text' : 'password'}
                    placeholder="New Password"
                    onChange={(e) => setNewPassword(e.target.value)} />
                <div className='view-password-icon-container'>
                    <FontAwesomeIcon
                        icon={viewNewPassword ? faEye : faEyeSlash}
                        onClick={() => setViewNewPassword(!viewNewPassword)}
                        className='view-password-icon' />
                </div>
            </div>
            <div className='field'>
                <label htmlFor="">Confirm New Password</label>
                <input
                    type={viewConfirmNewPassword ? 'text' : 'password'}
                    placeholder="Confirm New Password"
                    onChange={(e) => setConfirmNewPassword(e.target.value)} />
                <div className='view-password-icon-container'>
                    <FontAwesomeIcon
                        icon={viewConfirmNewPassword ? faEye : faEyeSlash}
                        onClick={() => setViewConfirmNewPassword(!viewConfirmNewPassword)}
                        className='view-password-icon' />
                </div>
            </div>
            <button className="change-password-btn" onClick={handlePasswordChange}>Change Password</button>
        </div>
    )
}

export default ChangePassword