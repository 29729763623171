import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './../Coach/CoachBookings/coachBookings.css';
import BookingCard from '../Coach/CoachBookings/BookingCard';
import Loader from '../Loader/Loader';
import { BASE_URL } from '../../constants';
import { selectStyles } from '../Admin/AddCoach';

const StudentBookings = () => {
    const userToken = localStorage.getItem('frotaToken');

    const sortOptions = [
        { value: 'newest', label: 'Newest' },
        { value: 'oldest', label: 'Oldest' },
        { value: 'date', label: 'Date' },
        // { value: 'endTime', label: 'End Time' },
        // { value: 'scheduledAt', label: 'Date' },
        // { value: 'status', label: 'Status' },
    ];
    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'declined', label: 'Declined' },
        { value: 'cancelled', label: 'Cancelled' },
    ];

    const [bookings, setBookings] = useState([]);
    const [allBookings, setAllBookings] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [sortOption, setSortOption] = useState(null);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [statusFilter, setStatusFilter] = useState({ value: 'all', label: 'All' });


    const fetchBookings = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}student/appointments`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const json = await response.json();
            const appointments = json?.data || [];
            // console.log(appointments)
            const pendingAppointments = appointments.map(appointment => ({
                id: appointment.id,
                startTime: appointment.startTime?.substring(0, 5) || '',
                endTime: appointment.endTime?.substring(0, 5) || '',
                scheduledAt: appointment.scheduledAt || '',
                student: appointment.student || {},
                coach: appointment.coach || {},
                speciality: appointment?.speciality,
                comments: appointment?.comments || '',
                status: appointment.status || '',
                charges: appointment.charges || '',
                isStudent: true
            }));

            setBookings(pendingAppointments);
            setAllBookings(pendingAppointments);
            setSortOption({ label: 'Newest', value: 'newest' });

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleApplyFilter = () => {
        const filteredBookings = allBookings.filter((booking) => {
            const bookingDate = new Date(booking.scheduledAt);
            const fromDate = dateFrom ? new Date(dateFrom) : null;
            const toDate = dateTo ? new Date(dateTo) : null;
            return (!fromDate || bookingDate >= fromDate) && (!toDate || bookingDate <= toDate);
        });

        let statusFilteredBookings = [...filteredBookings];

        if (statusFilter.value !== 'all') {
            statusFilteredBookings = filteredBookings.filter((booking) => {
                return booking.status === statusFilter.value;
            })
        }
        setBookings(statusFilteredBookings);
    }


    const handleClearFilter = () => {
        setDateFrom('');
        setDateTo('');
        setStatusFilter({ value: 'all', label: 'All' });
        setBookings(allBookings);
    }


    useEffect(() => {
        fetchBookings()
    }, [refresh])

    useEffect(() => {
        if (sortOption) {
            let sortedBookings = [...bookings];
            switch (sortOption.value) {
                case 'newest':
                    sortedBookings.sort((a, b) => new Date(b.id) - new Date(a.id));
                    break;
                case 'oldest':
                    sortedBookings.sort((a, b) => new Date(a.id) - new Date(b.id));
                    break;
                case 'oldest':
                    sortedBookings.sort((a, b) => new Date(a.scheduledAt) - new Date(b.scheduledAt));
                    break;
                default:
                    sortedBookings.sort((a, b) => {
                        if (a[sortOption.value] < b[sortOption.value]) return -1;
                        if (a[sortOption.value] > b[sortOption.value]) return 1;
                        return 0;
                    });
                    break;
            }
            setBookings(sortedBookings);
            setAllBookings(sortedBookings);
        }
    }, [sortOption]);

    return (
        <div className='bookings-container'>
            <Loader isLoading={isLoading} />
            <h2 style={{ fontSize: '30px', textAlign: 'center', marginBottom: '20px' }}>MY BOOKINGS</h2>

            <div className="filter-main-container">
                <div className='filters-container'>
                    <div className="filter-item">
                        <label htmlFor="dateFrom">Date From:</label>
                        <input
                            type="date"
                            id="dateFrom"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                        />
                    </div>
                    <div className="filter-item">
                        <label htmlFor="dateTo">Date To:</label>
                        <input
                            type="date"
                            id="dateTo"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                        />
                    </div>
                    <div className="filter-item">
                        <label htmlFor="statusFilter">Status:</label>
                        <Select
                            id="statusFilter"
                            className='sort-select'
                            options={statusOptions}
                            value={statusFilter}
                            onChange={(option) => setStatusFilter(option)}
                            placeholder="Select Status"
                            styles={selectStyles}
                        />
                    </div>
                </div>
                <div className='filter-btn-container'>
                    <button className="btn clear-filter" onClick={handleClearFilter}>Clear Filters</button>
                    <button className="btn apply-filter" onClick={handleApplyFilter}>Apply Filters</button>
                </div>
            </div>


            <div className="select-filter-container m-auto mb-4" style={{ width: '220px' }}>
                <label htmlFor="">Sort By:</label>
                <Select
                    className="sort-select"
                    options={sortOptions}
                    value={sortOption}
                    onChange={(option) => setSortOption(option)}
                    placeholder="Sort by..."
                    styles={selectStyles}
                />
            </div>
            <div className="bookings-list">
                {bookings?.map(booking => (
                    <BookingCard key={booking.id} booking={booking} refresh={refresh} setRefresh={setRefresh} />
                ))}
            </div>
        </div>
    )
}

export default StudentBookings