import React, { useEffect, useState } from 'react';
import './admin.css'
import ImageUploader from '../ImageUploader/ImageUploader';
import { BASE_URL, passwordPattern } from '../../constants';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Loader from '../Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const AddCoach = () => {

    const userToken = localStorage.getItem('frotaToken');
    // State variables to store form inputs

    const [services, setServices] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [clientDescription, setClientDescription] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [price, setPrice] = useState('');

    const [viewPassword, setViewPassword] = useState(false);

    // Function to clear form fields
    // const clearForm = () => {
    //     setName('');
    //     setAddress('');
    //     setPhone('');
    //     setEmail('');
    //     setClientDescription('');
    // };

    const handleServiceChange = (selectedOption) => {
        setSelectedSpeciality(selectedOption);
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name) {
            toast.warn('Please Enter a Name');
            return;
        }
        if (!phone) {
            toast.warn('Please Enter a Phone Number.');
            return;
        }
        if (!email) {
            toast.warn('Please Enter an Email Address.');
            return;
        }
        if (!password) {
            toast.warn('Please Enter a Password.');
            return;
        }
        // if (!address) {
        //     toast.warn('Please Enter an Address.');
        //     return;
        // }
        if (!selectedSpeciality || selectedSpeciality.length === 0) {
            toast.warn('Please Select a Speciality.')
            return;
        }
        // if (!clientDescription) {
        //     toast.warn('Please Enter a Description.');
        //     return;
        // }

        const isValidPassword = passwordPattern.test(password);
        if (!isValidPassword) {
            toast.error('Password must contain at least One Uppercase letter, one Lowercase letter, one Number, and one Special Character, and be at least 8 characters long.')
            return;
        }

        const specialityIds = selectedSpeciality.map(speciality => speciality.value);

        setIsLoading(true);

        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('password', password);
        if (address) {
            formData.append('address', address);
        }

        specialityIds?.forEach(id => {
            formData.append('specialityId[]', id);
        });

        if (!selectedSpeciality || selectedSpeciality?.length === 0) {
            toast.error("Please Select atleast One Service.")
            setIsLoading(false);
            return;
        }
        // formData.append('specialityId', JSON.stringify(specialityIds));
        formData.append('charges', price);

        if (clientDescription) {
            formData.append('description', clientDescription);
        }

        if (profileImage) {
            formData.append('image', profileImage);
        }

        // console.log("FormData:", formData);

        try {
            const response = await fetch(`${BASE_URL}admin/coach_sign_up`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`
                },
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    toast.success('Coach Created Successfully.');
                    // alert(data.message);
                }
                // console.log('Coach sign-up successful:', data);
                setIsLoading(false);
                // Handle successful sign-up, such as displaying a success message
                return data; // Return data if further processing is needed
            } else {
                // Handle sign-up failure
                const error = await response.json();
                console.error('Coach sign-up failed:', error);
                toast.error(error.message);
                // Handle error, such as displaying an error message
                setIsLoading(false);
                throw new Error('Coach sign-up failed'); // Throw error for further handling
            }
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle network errors or other exceptions
            setIsLoading(false);
            throw new Error('An unexpected error occurred'); // Throw error for further handling
        }
    };

    const fetchSpecialities = async () => {
        try {
            const response = await fetch(`${BASE_URL}speciality`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if (response.ok) {
                const json = await response.json();
                setServices(json.data);
            } else {
                console.error('Failed to fetch specialities:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching specialities:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchSpecialities();

    }, [])

    return (
        <div className='add-student-container'>
            <Loader isLoading={isLoading} />
            <form onSubmit={handleSubmit}>
                <h2 className='title'>ADD COACH</h2>
                <div className="form-container">
                    <div className="left-container">
                        <div className='form-field'>
                            <label htmlFor='name'>Name: </label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                placeholder="Enter Coach's Name"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='email'>Email: </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                placeholder="Enter Coach's Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='phone'>Phone: </label>
                            <input
                                type="text"
                                id="phone"
                                value={phone}
                                placeholder="Enter Phone Number"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='password'>Password: </label>
                            <input
                                type={viewPassword ? 'text' : 'password'}
                                id="password"
                                value={password}
                                placeholder="Enter Coach's Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className='view-password-icon-container'>
                                <FontAwesomeIcon
                                    icon={viewPassword ? faEye : faEyeSlash}
                                    onClick={() => setViewPassword(!viewPassword)}
                                    className='view-password-icon' />
                            </div>

                        </div>

                        <div className='select-field'>
                            <label className='select-label'>Select Service:</label>
                            <Select
                                value={selectedSpeciality}
                                onChange={handleServiceChange}
                                options={services.map(option => ({
                                    value: option.id,
                                    label: option.name,
                                    imageUrl: option.mediaUrl
                                }))}
                                styles={selectStyles}
                                isMulti={true}
                            />
                        </div>

                        <div className='form-field'>
                            <label htmlFor='phone'>Price: </label>
                            <input
                                type="text"
                                id="price"
                                value={price}
                                placeholder="Enter Price"
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>

                    </div>
                    <div className="right-container">
                        <ImageUploader setProfileImage={setProfileImage} />
                    </div>
                </div>

                <div className="bottom-container">
                    <div className='form-field'>
                        <label htmlFor='address'>Address: </label>
                        <input
                            type="text"
                            id="address"
                            value={address}
                            placeholder="Enter Coach's Address"
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <div className='form-field'>
                        <label htmlFor='clientDescription'>Employee Description: </label>
                        <textarea
                            type="text"
                            id="clientDescription"
                            value={clientDescription}
                            rows={4}
                            placeholder='Enter Some Employee Description...'
                            onChange={(e) => setClientDescription(e.target.value)}
                        />
                    </div>

                    <div className="btn-container">
                        <button type="submit" className='submit-btn'>Add Coach</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export const selectStyles = {
    control: (styles, { isFocused, isDisabled }) => ({
        ...styles,
        minWidth: '145px',
        backgroundColor: isFocused ? '#FFF' : 'white', // Change background color based on focus
        borderColor: isFocused ? 'var(--red)' : '#ced4da', // Change border color based on focus
        borderSize: 1,
        boxShadow: isFocused ? '0 0 0 1px var(--red)' : '', // Add box shadow for focus
        cursor: isDisabled ? 'not-allowed' : 'default', // Set cursor based on disabled state
        '&:hover': {
            borderColor: isFocused ? 'var(--red)' : '#adb5bd', // Change border color on hover
        },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? 'var(--red)' : isFocused ? '#3331' : 'transparent', // Change background color based on selection and focus
            color: isSelected ? '#FFF' : '', // Change text color based on selection
            cursor: isDisabled ? 'not-allowed' : 'default', // Set cursor based on option state
            '&:hover': {
                backgroundColor: isSelected ? 'var(--red)' : '#f8f9fa', // Change background color on hover
                color: isSelected ? '#FFF' : '', // Change text color on hover
            },
        };
    },
    menu: (styles) => ({ ...styles, zIndex: 9999 }), // Increase z-index to ensure dropdown menu appears on top
};

export default AddCoach;
